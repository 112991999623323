@font-face {
    font-family: 'GreatVibes';
    src: local('GreatVibes'), url(./fonts/GreatVibes-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'PlayfairDisplay';
    src: local('PlayfairDisplay'), url(./fonts/PlayfairDisplay-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'PlayfairDisplay-Italic';
    src: local('PlayfairDisplay-Italic'), url(./fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./fonts/Montserrat-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-thin-italic';
    src: local('Montserrat-thin-italic'), url(./fonts/Montserrat-ThinItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'Montserrat-italic';
    src: local('Montserrat-italic'), url(./fonts/Montserrat-Italic.ttf) format('truetype');
}

h1 {
    font-family: 'GreatVibes';
}

h2 {
    font-family: 'Montserrat-thin-italic';
}

.nav .disabled {
    font-family: 'Montserrat-thin-italic';
}

.nav {
    font-family: 'Montserrat-italic';
}

.biggish-text {
    font-size: larger;
}

.nav-link {
    color: #507CB0
}

.nav-link:hover {
    color: #6c757d
}